<template>
  <div class="mt-4 pt-3 border-t">
    <div class="flex justify-between mb-2 items-center">
      <div class="text-gray-500 font-mono text-sm">
        Taster to Sub conversion:
      </div>
      <div class="font-bold text-gray-700">{{ rate }}%</div>
    </div>
    <div class="w-full bg-gray-200 rounded-full h-2">
      <div
        class="bg-indigo-500 h-2 rounded-full transition-all duration-500"
        :style="{ width: `${rate}%` }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConversionBar',
  props: {
    rate: {
      type: [Number, String],
      required: true
    }
  }
};
</script>
