<template>
  <div class="grid grid-cols-2 lg:grid-cols-4 gap-[1px] bg-gray-200">
    <div class="py-2 px-3 bg-white">
      <div class="font-bold text-xl">
        {{ formatNumber(stats.totalTasterBoxes) }}
      </div>
      <div class="text-gray-500 font-mono text-sm">Total Taster Boxes</div>
    </div>
    <div class="py-2 px-3 bg-white">
      <div class="font-bold text-xl">
        {{ formatNumber(stats.totalConversions) }}
      </div>
      <div class="text-gray-500 font-mono text-sm">Total Subscriptions</div>
    </div>
    <div class="py-2 px-3 bg-white">
      <div class="font-bold text-xl">
        {{ formatNumber(stats.noConversion) }}
      </div>
      <div class="text-gray-500 font-mono text-sm">No Subscription</div>
    </div>
    <div class="py-2 px-3 bg-white">
      <div class="font-bold text-xl">
        {{ formatNumber(stats.averageBoxes) }}
      </div>
      <div class="text-gray-500 font-mono text-sm">Avg. Boxes</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatsGrid',
  props: {
    stats: {
      type: Object,
      required: true,
      validator: function (value) {
        return [
          'totalTasterBoxes',
          'totalConversions',
          'noConversion',
          'averageBoxes'
        ].every(key => key in value);
      }
    }
  },
  methods: {
    formatNumber(number) {
      return new Intl.NumberFormat('en-GB').format(number);
    }
  }
};
</script>
