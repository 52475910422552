<template>
  <div class="h-full flex flex-col">
    <div class="text-xl font-semibold py-5 flex-none px-6">Ads</div>
    <div
      class="text-sm text-red-500 bg-red-200 p-2 justify-center mx-6 rounded text-lg text-gray-500 px-6 flex items-center gap-x-3"
    >
      <font-awesome-icon
        :icon="['fad', 'triangle-exclamation']"
        class="text-red-500 text-2xl"
      />
      WORK IN PROGRESS, Don't use these values as they are unconfirmed
    </div>
    <div class="flex-grow p-6">
      <loading :is-loading="isLoading">
        <div v-if="ads && ads.length" class="w-full">
          <div class="grid grid-cols-1 gap-4">
            <div
              v-for="ad in sortedAds"
              :key="ad"
              class="border rounded-lg p-4"
            >
              <div
                class="font-semibold mb-4 flex items-center leading-none gap-x-2 truncate"
              >
                <span class="text-gray-400 font-mono font-light leading-none"
                  >Campaign
                  <span
                    class="font-bold font-sans text-base truncate text-gray-900"
                  >
                    {{ ad }}</span
                  ></span
                >
              </div>
              <div v-if="getAdStats(ad)" class="space-y-3">
                <stats-grid :stats="getFormattedStats(ad)" />
                <conversion-bar :rate="getFormattedStats(ad).conversionRate" />
                <descriptions :stats="getAdStats(ad)[0]" />
              </div>
              <div v-else class="text-gray-500 text-sm">Loading ad data...</div>
            </div>
          </div>
        </div>
        <div v-else-if="ads && !ads.length" class="text-center text-gray-500">
          No ads found
        </div>
      </loading>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loading from '@/components/common/ui/Loading';
import StatsGrid from './components/StatsGrid.vue';
import ConversionBar from './components/ConversionBar.vue';
import Descriptions from './components/Descriptions.vue';

export default {
  name: 'AdsList',
  components: {
    Loading,
    StatsGrid,
    ConversionBar,
    Descriptions
  },
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    ...mapGetters({
      ads: 'getAdsData',
      getAdStats: 'getAdStats',
      isAdmin: 'isAdmin'
    }),
    sortedAds() {
      if (!this.ads) return [];
      return [...this.ads].sort((a, b) => {
        const statsA = this.getFormattedStats(a);
        const statsB = this.getFormattedStats(b);

        if (!statsA || !statsB) return 0;
        return (
          parseFloat(statsB.conversionRate) - parseFloat(statsA.conversionRate)
        );
      });
    }
  },
  created() {
    this.fetchAds();
  },
  methods: {
    async fetchAds() {
      this.isLoading = true;
      try {
        await this.$store.dispatch('getAds');
      } catch (error) {
        console.error('Error fetching ads:', error);
      }
      this.isLoading = false;
    },
    getFormattedStats(ad) {
      const stats = this.getAdStats(ad)?.[0];
      if (!stats) return null;

      const active = stats.active || 0;
      const inactive = stats.inactive || 0;
      const nopurchase = stats.nopurchase || 0;

      // Total taster boxes distributed through this ad
      const totalTasterBoxes = active + inactive + nopurchase;

      // Total conversions (both active and inactive subscriptions)
      const totalConversions = active + inactive;

      // Those who didn't convert
      const noConversion = nopurchase;

      return {
        totalTasterBoxes,
        totalConversions,
        noConversion,
        averageBoxes: stats.boxes || 0,
        conversionRate:
          totalTasterBoxes === 0
            ? 0
            : ((totalConversions / totalTasterBoxes) * 100).toFixed(1)
      };
    }
  }
};
</script>
