<template>
  <div class="mt-4 pt-1">
    <button
      @click="isExpanded = !isExpanded"
      class="w-full flex items-center justify-between text-gray-500 hover:text-gray-700 text-xs font-mono"
    >
      <span>More Information</span>
      <font-awesome-icon
        :icon="['fal', 'chevron-down']"
        class="transform transition-transform duration-200"
        :class="{ 'rotate-180': isExpanded }"
      />
    </button>

    <transition
      enter-active-class="transition-all duration-200 ease-out"
      leave-active-class="transition-all duration-200 ease-in"
      enter-from-class="opacity-0 max-h-0"
      enter-to-class="opacity-100 max-h-96"
      leave-from-class="opacity-100 max-h-96"
      leave-to-class="opacity-0 max-h-0"
    >
      <div
        v-show="isExpanded"
        class="overflow-hidden text-xs text-gray-500 font-mono space-y-4 pt-4"
      >
        <div class="flex">
          <span class="font-medium w-32 flex-none">Active:</span>
          <span class="font-medium">{{ stats.activeDescription }}</span>
        </div>
        <div class="flex">
          <span class="font-medium w-32 flex-none">Avg. Boxes:</span>
          <span class="font-medium">{{ stats.boxesDescription }}</span>
        </div>
        <div class="flex">
          <span class="font-medium w-32 flex-none">Inactive:</span>
          <span class="font-medium">{{ stats.nopurchaseDescription }}</span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Descriptions',
  props: {
    stats: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isExpanded: false
    };
  }
};
</script>
